import React from "react";

export const Contact = () => {
  return (
    <section id="about">
            <div className="container px-4">
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-8">
                        <h2>Para establecer contacto escribe a direccion email:</h2>
                        <p className="lead">consultas@dximagenvet.com</p>                        
                    </div>
                </div>
            </div>
        </section>
  );
};
